import { defineNuxtRouteMiddleware } from 'nuxt/app';
import { storeToRefs } from 'pinia';
import type { RouteLocationNormalized } from 'vue-router';

import { checkProductBuildReleaseStatusApi, fetchIsAllRunOptionTypeApi } from '@/apis/build.api';
import { RELEASE_STATUS_BUILD } from '@/constants/build.const';
import { PRODUCT_TYPE_DETAIL } from '@/constants/products.const';
import { PRODUCT_BUILD_PAGE_URL } from '@/constants/url.const';
import { useBuildStore } from '@/stores/build.store';
import useProductStore from '@/stores/product.store';
import { redirectTo } from '@/utils/common.util';

export default defineNuxtRouteMiddleware(async (to: RouteLocationNormalized) => {
  const buildId = to.params.buildId as string;
  const productId = to.params.productId as string;
  const buildStore = useBuildStore();
  const { buildDetail } = storeToRefs(buildStore);

  // Get the product store to access currentProductDetailType
  const productStore = useProductStore();
  const { currentProductDetailType } = storeToRefs(productStore);

  // Only check PC run option exists for GAME_BASIC and GAME_DEMO
  if (
    currentProductDetailType.value === PRODUCT_TYPE_DETAIL.GAME_BASIC ||
    currentProductDetailType.value === PRODUCT_TYPE_DETAIL.GAME_DEMO
  ) {
    try {
      const runOptionResponse = await fetchIsAllRunOptionTypeApi(productId);
      if (!runOptionResponse?.isExistPcOption) {
        return redirectTo(`${PRODUCT_BUILD_PAGE_URL}/${buildId}`);
      }
    } catch (error) {
      return redirectTo(`${PRODUCT_BUILD_PAGE_URL}/${buildId}`);
    }
  }

  // Check build release status
  try {
    const releaseStatus = await checkProductBuildReleaseStatusApi(productId);
    if (!releaseStatus || !releaseStatus.isProductRelease || !releaseStatus.isExistBuildRelease) {
      return redirectTo(`${PRODUCT_BUILD_PAGE_URL}/${buildId}`);
    }
  } catch (error) {
    return redirectTo(`${PRODUCT_BUILD_PAGE_URL}/${buildId}`);
  }

  if (
    buildDetail.value?.releaseStatus === RELEASE_STATUS_BUILD.VERIFY_REVIEW_REJECTED ||
    buildDetail.value?.releaseStatus === RELEASE_STATUS_BUILD.RELEASE_UNDER_REVIEW ||
    buildDetail.value?.releaseStatus === RELEASE_STATUS_BUILD.END ||
    buildDetail.value?.releaseStatus === RELEASE_STATUS_BUILD.APPLY_LIVE
  ) {
    return redirectTo(`${PRODUCT_BUILD_PAGE_URL}/${buildId}`);
  }

  return true;
});
